<template>
  <el-container class="login-container">
    <div class="QRcodeMain">
      <div class="newsArea">
        <div class="newPart1">
        <div class="bannerText"><div class="line"></div>商务合作专栏</div>
        <el-carousel height="100px">
          <el-carousel-item v-for="item in banner" :key="item.image">
            <el-image :src="item.image"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="newPart2">
        <div class="bannerText">「可能是」国内最好的球场管理系统</div>
        <el-carousel height="100px">
          <el-carousel-item v-for="item in banner2" :key="item.image">
            <el-image :src="item.image"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
      <div id="weixin" class="QRcode"></div>
    </div>
  </el-container>
</template>
<script type="text/javascript" src="http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>
<script>
export default {
  data() {
    return {
      banner:[
        {
          image:"https://img1.baidu.com/it/u=3183565747,4085678136&fm=253&fmt=auto&app=138&f=JPEG?w=1000&h=500"
        },
        {
          image:"https://img0.baidu.com/it/u=3447847323,3579500877&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=310"
        }
      ],
      banner2:[
        {
          image:"https://img2.baidu.com/it/u=1664375150,2646904745&fm=253&fmt=auto&app=120&f=JPEG?w=465&h=300"
        },
        {
          image:"https://img0.baidu.com/it/u=3437919487,1968736136&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=502"
        }
      ]
    };
  },

  methods: {
    getCodeMes() {
      let code = this.getQueryString("code");
      console.log("地址中的code:", code);
      if (!code) {
        this.createWxQrcode();
      } else {
        this.getLoginMes(code);
      }
    },

    //使用正则读取url里的code
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      // console.log(window.location.search);
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return "";
    },

    async getLoginMes(code) {
      console.log('通过返回的code获取后台token:', code);
      const { res } = await this.$http.get(`/AdminUser/CheckLogin?code=${code}`);
      if (res.isSuccess) {
        localStorage.setItem('Authorization', res.data.token);
        localStorage.setItem('user_info', JSON.stringify(res.data.info));

        this.$http.get('/Field/GetFieldList').then(response => {
          if (response.res.isSuccess) {
            const fieldList = response.res.data || [];
            if (fieldList.length) {
              localStorage.setItem('fieldId', fieldList[0].fieldId);
              localStorage.setItem('currentField', JSON.stringify(fieldList[0]));
            }
            location.reload();
          }
        });
      }
    },

    createWxQrcode() {
      // 生成微信登录二维码
      var obj = new WxLogin({
        self_redirect: false,
        id: "weixin", // 页面显示二维码的容器id
        appid: "wxc7ca6974731e30ac", // 微信官方提供的测试id
        scope: "snsapi_login",
        // redirect_uri: encodeURI("http://qiuchangzhu.ladesports.com/logindev/login"), // 微信官方中的测试环境url地址
        redirect_uri: encodeURI("http://qiuchangzhu.ladesports.com/login"), // 微信官方中的正式环境url地址
        state: "bind",
        style: "black",
        href: "",
      });
    }
  },
  mounted() {
    // this.createWxQrcode();
    this.getCodeMes();
  }
};


</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: url(../../assets/1203-qiuchangzhu.jpg) repeat left top;

.QRcodeMain {
  display: flex;
  align-items: center;
  position: absolute;
  width: auto;
  height: 450px;
  margin: 0 auto;
  border-radius: 5px;
  background: #fff;
}

.QRcode {
  width: 350px;
  height: 100%;
  padding: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 5px;
}

.newsArea {
  width: 350px;
  height: 100%;
  padding: 20px;
  margin-top: 30px;
  border-right: 1px solid #eeeeee;
}

.newPart1,
.newPart2{
  margin-top: 28px;
}

.bannerText{
  height: 30px;
  display: flex;
  align-items: center;
}

.line{
  height: 15px;
  width: 4px;
  border-radius: 10px;
  margin-right: 3px;
  background-color: #bdbdbd;
}
}
</style>
